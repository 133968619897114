import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Link as Link1 } from 'react-scroll';
import * as Unicons from "@iconscout/react-unicons";

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
     // Handle scroll event
     useEffect(() => {
        const windowScroll = () => {
            const navbar = document.getElementById("navbar");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                navbar?.classList.add("is-sticky");
            } else {
                navbar?.classList.remove("is-sticky");
            }

            const mybutton = document.getElementById("back-to-top");
            if (mybutton) {
                if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                    mybutton.classList.add("flex");
                    mybutton.classList.remove("hidden");
                } else {
                    mybutton.classList.add("hidden");
                    mybutton.classList.remove("flex");
                }
            }
        };

        window.addEventListener("scroll", windowScroll);
        return () => {
            window.removeEventListener("scroll", windowScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };
    
    const hideMobileNav = () => {
        setIsMenuOpen(false);
    }

    return (
        <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-between">
                <Link to="/" className="navbar-brand md:me-8" href="/">
                    <img src="/images/logo.png" className="inline-block dark:hidden" alt="Brand Logo" />
                    <img src="/images/logo.png" className="hidden dark:inline-block" alt="Brand Logo" />
                </Link>
                <div className="nav-icons flex items-center lg_992:order-2 ms-auto md:ms-8">
                    {/* <!-- Navbar Button --> */}
                    <ul className="list-none menu-social mb-0">
                        <li className="inline-flex">
                            <Link to="" className="ms-1">
                                <span className="login-btn-primary"><span className="btn btn-icon btn-sm rounded-full bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white"><Unicons.UilDribbble width={16} /></span></span>
                                <span className="login-btn-light"><span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Unicons.UilDribbble width={16} /></span></span>
                            </Link>
                            <Link to="" className="ms-1">
                                <span className="login-btn-primary "><span className="btn btn-icon btn-sm rounded-full bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white"><Unicons.UilBehance width={16} /></span></span>
                                <span className="login-btn-light "><span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Unicons.UilBehance width={16} /></span></span>
                            </Link>
                            <Link to="" className="ms-1">
                                <span className="login-btn-primary"><span className="btn btn-icon btn-sm rounded-full bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white"><Unicons.UilInstagram width={16} /></span></span>
                                <span className="login-btn-light"><span className="btn btn-icon btn-sm rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"><Unicons.UilInstagram width={16} /></span></span>
                            </Link>
                        </li>
                    </ul>
                    {/* <!-- Navbar Collapse Manu Button --> */}
                    <button data-collapse="menu-collapse" type="button" className="collapse-btn inline-flex items-center ms-2 text-dark dark:text-white lg_992:hidden" aria-controls="menu-collapse" onClick={toggleMenu} aria-expanded={isMenuOpen}>
                        <span className="sr-only">Navigation Menu</span>
                        <i className="mdi mdi-menu text-[24px]"></i>
                    </button>
                </div>
                {/* <!-- Navbar Manu --> */}
                <div className={`navigation lg_992:order-1 lg_992:flex ${isMenuOpen ? "block" : "hidden"} ms-auto`} id="menu-collapse">
                    <ul className="navbar-nav" id="navbar-navlist">
                        <li className="nav-item">
                            <Link1 to="home" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Home</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 to="about" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">About Me</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 to="service" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Services</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 to="experience" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Experience</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 to="project" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Projects</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 to="blog" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Blogs</Link1>
                        </li>
                        <li className="nav-item">
                            <Link1 to="contact" onClick={hideMobileNav} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">Contact Me</Link1>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}